import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';
import Slider from 'react-slick';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import SectionEarlyAccess from '../components/sections/SectionEarlyAccess';
import Popup from '../components/atoms/Popup';

import useResizeScreen from '../hooks/useResize';
import useHover from '../hooks/useHover';
import useInterval from '../hooks/useInterval';

import * as Routes from '../constants/routes';

import IllustrationSpiral from '../assets/images/home-page/Illustration-spiral.png';
import IconArrowTriangle from '../assets/images/icons/svg/icon_arrow_triangle.inline.svg';
import IconCirclePlus from '../assets/images/icons/svg/icon_circle_plus.inline.svg';
import IconLeft from '../assets/images/icons/icon/arrow/left.inline.svg';
import IconRight from '../assets/images/icons/icon/arrow/right.inline.svg';
import logoApacheCamel from '../assets/images/home-page/Apache-Camel.svg';
import logoKubernetes from '../assets/images/home-page/Kubernetes_logo.svg';
import logoCircleApacheCamel from '../assets/images/icons/logo-svg/apache_camel.svg';
import logoCircleKubernetes from '../assets/images/icons/logo-svg/kubernetes.svg';
import iconTrusted from '../assets/images/icons/general-svg/icon-trusted.svg';
import iconLowCode from '../assets/images/icons/general-svg/icon-low-code.svg';
import iconOpenSource from '../assets/images/icons/general-svg/icon-open-source.svg';
import iconRealTimeIntegration from '../assets/images/icons/general-svg/icon-real-time-integration.svg';
import iconBuildApis from '../assets/images/icons/general-svg/icon-build-apis.svg';
import iconDatabasesChangeData from '../assets/images/icons/general-svg/icon-databeses-change-data.svg';
import iconEventsStreaming from '../assets/images/icons/general-svg/icon-events-streaming.svg';
import iconServeless from '../assets/images/icons/general-svg/icon-serverless.svg';
import iconHybridCloudNative from '../assets/images/icons/general-svg/icon-hybrid-cloud-native.svg';
import iconConsumberSql from '../assets/images/icons/general-svg/icon-consumer-sql.svg';
import iconMiscDelayer from '../assets/images/icons/general-svg/icon-misc-delayer.svg';
import iconProducerLog from '../assets/images/icons/general-svg/icon-producer-log.svg';
import iconTransformMap from '../assets/images/icons/general-svg/icon-transform-map.svg';
import iconOrchestrationLoop from '../assets/images/icons/general-svg/icon-orchestration-loop.svg';
import iconRoutingRecipientList from '../assets/images/icons/general-svg/icon-routing-recipient-list.svg';
import iconConsumerTimer from '../assets/images/icons/general-svg/icon-consumer-timer.svg';
import iconApiPost from '../assets/images/icons/general-svg/icon-api-post.svg';
import DecorationLines3 from '../assets/images/decorations/lines-3.svg';
import DecorationLines4 from '../assets/images/decorations/lines-4.svg';

import '../assets/styles/pages/home-page.scss';

import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
// import PopupDemoContent from '../components/atoms/Popup/PopupDemoContent';
const PopupDemoContent = React.lazy(() => import('../components/atoms/Popup/PopupDemoContent'));

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-next" onClick={onClick}>
      <IconRight />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-prev" onClick={onClick}>
      <IconLeft />
    </div>
  );
}

const IndexPage = () => {
  const { isMobile } = useResizeScreen();

  return (
    <Layout title="Jetic - Cloud-native Integration Platform">
      <div className="home-page">
        <SectionFirst isMobile={isMobile} />
        <SectionSecond isMobile={isMobile} />
        <SectionThird isMobile={isMobile} />
        <SectionFourth isMobile={isMobile} />
        <SectionFifth />
        <SectionFifthAndHalf />
        <SectionSixth isMobile={isMobile} />
        {!isMobile && <SectionSeventh />}
        <SectionEight isMobile={isMobile} />
        <div className="position-relative">
          <div className="c-decoration" style={{ top: -223 }}>
            <img src={DecorationLines3} alt="decoration" />
          </div>
          <SectionEarlyAccess />
        </div>
      </div>
    </Layout>
  );
};

const SectionFirst = ({ isMobile }) => (
    <section className="first-section">
      <div className="container">
        <div className="first-section__wrapper">
          <div className="first-section__content">
            <h1 className="header-700 mb-3 pb-md-3">
              Cloud-native Integration Platform on Apache Camel
            </h1>
            <h2 className="body-300 gray-500 mb-32">
              Jetic is the first and only cloud-native API & Integration Platform based on Apache
              Camel. An iPaaS solution for avoiding costly vendor lock-ins and regaining command of
              your development - without any drawbacks.
            </h2>
            <div className="btn-wrapper mb-32">
              <a
                className="c-btn c-btn--primary"
                href="https://app.jetic.io/registration"
                target="_blank"
                rel="noopener noreferrer"
              >
                Free for Developers
              </a>
              <a className="c-btn c-btn--white" href="#demo">
                Schedule Demo
              </a>
            </div>
            <div className="logo-wrapper mb-32">
              <img className="me-4" src={logoApacheCamel} alt="Apache-Camel" height={28} />
              <img src={logoKubernetes} alt="Kubernetes" height={28} />
            </div>
          </div>
        </div>
      </div>
      <div className="first-section__image-wrapper">
        <div className="first-section__image-wrapper__bg-l" />
        <div className="first-section__image-wrapper__bg-r" />
        <div className="container">
          <div className="row">
            <div className="col-md-11 mx-auto">
              <div className="position-relative">
                <Popup
                  trigger={
                    <button className="watch-demo">
                      <IconArrowTriangle />
                      <span>Watch 2 minute Demo</span>
                    </button>
                  }
                  modal
                >
                  {(close) =>
                    typeof window !== 'undefined' &&
                    typeof document !== 'undefined' && (
                      <React.Suspense fallback={<div className="py-5">Loading...</div>}>
                        <PopupDemoContent close={close} />
                      </React.Suspense>
                    )
                  }
                </Popup>
              </div>
              {!isMobile ? (
                <StaticImage
                  src="../assets/images/home-page/Illustration-main.png"
                  className="first-section__image illustration w-100"
                  alt="Manage Hybrid Integration Clusters"
                />
              ) : (
                <StaticImage
                  src="../assets/images/home-page/Illustration-main--mobile.png"
                  className="first-section__image illustration w-100"
                  alt="Manage Hybrid Integration Clusters"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
const SectionSecond = ({ isMobile }) => (
    <section className="c-section--white">
      <div className="container">
        <div className="heading-wrapper" style={{ maxWidth: 808 }}>
          <h3 className="header-600 mb-3">Jumpstart your Integration Hub in the Cloud</h3>
          <h4 className="body-300 gray-400 mb-32">
            A reliable and robust serverless platform that enables developers to engineer, deliver
            and maintain scalable APIs and integrations using hundreds of pre-built connectors and
            patterns.
          </h4>
        </div>
      </div>
      {!isMobile ? (
        <img
          src={IllustrationSpiral}
          alt="Manage Hybrid Integration Clusters"
          className="w-100"
          loading="lazy"
        />
      ) : (
        <StaticImage
          src="../assets/images/home-page/Illustration-spiral--mobile.png"
          alt="Manage Hybrid Integration Clusters"
          imgClassName="illustration"
          layout="fullWidth"
        />
      )}
    </section>
  );
const SectionThird = ({ isMobile }) => {
  const CardTrusted = () => (
    <>
      <img className="mb-4" src={iconTrusted} alt="Trusted Framework" height={48} width={48} />
      <h5 className="header-400">Trusted Framework</h5>
      <p className="body-300 gray-400">
        From startup to Fortune 500: thousands of companies rely on Apache Camel every day. Get
        there faster with Jetic.
      </p>
    </>
  );
  const CardLowCode = () => (
    <>
      <img className="mb-4" src={iconLowCode} alt="Low-code" height={48} width={48} />
      <h5 className="header-400">Low-code Development</h5>
      <p className="body-300 gray-400">
        300+ connectors pre-build, 50+ data formats pre-build and 100+ patterns as simple as drag
        and drop.
      </p>
    </>
  );
  const CardOpen = () => (
    <>
      <img className="mb-4" src={iconOpenSource} alt="Open" height={48} width={48} />
      <h5 className="header-400">No Vendor Lock-in</h5>
      <p className="body-300 gray-400">
        You got that right. Zero vendor lock-in ever. Native open-source framework code pushed into
        your Git.
      </p>
    </>
  );

  return (
    <section className="c-section--gray third-section">
      <div className="container">
        <div className="heading-wrapper">
          <span className="c-label c-label--primary header-100 mb-3">The Jetic Difference</span>
          <h3 className="header-600 mb-3">
            The world's most trusted and powerful open-source integration framework
          </h3>
          <h4 className="body-300 gray-400 mb-64">
            Jetic delivers the benefits of open-source frameworks while making it more accessible.
          </h4>
        </div>
        {!isMobile && (
          <div className="row">
            <div className="col-md-4">
              <div className="c-card c-card--center mb-4">
                <CardTrusted />
              </div>
            </div>
            <div className="col-md-4">
              <div className="c-card c-card--center mb-4">
                <CardLowCode />
              </div>
            </div>
            <div className="col-md-4">
              <div className="c-card c-card--center mb-4">
                <CardOpen />
              </div>
            </div>
          </div>
        )}
      </div>
      {isMobile && (
        <div>
          <Slider
            {...{
              arrows: true,
              dots: false,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '20px',
              nextArrow: <NextArrow />,
              prevArrow: <PrevArrow />,
            }}
          >
            <div>
              <div className="c-card c-card--center mx-1">
                <CardTrusted />
              </div>
            </div>
            <div>
              <div className="c-card c-card--center mx-1">
                <CardLowCode />
              </div>
            </div>
            <div>
              <div className="c-card c-card--center mx-1">
                <CardOpen />
              </div>
            </div>
          </Slider>
        </div>
      )}
    </section>
  );
};

const SectionFourth = ({ isMobile }) => {
  const [count, setCount] = useState(0);
  const [activeLabel, setActiveLabel] = useState('Components');

  const handleActiveLabel = (event, newLabel) => {
    event.preventDefault();
    setActiveLabel(newLabel);
  };

  const ComponentsContent = () => (
    <>
      <div className="col-md-6">
        <h6 className="header-400 mb-3">Components</h6>
        <p className="body-300 gray-400">
          Use hundreds of pre-built components for better direct communication with your partners -
          whether these are apps, systems, or cloud technologies.
        </p>
        <a href="#demo" className="c-btn c-btn--white">
          Schedule a demo
        </a>
      </div>
      <div className="col-md-6">
        {!isMobile ? (
          <StaticImage
            src="../assets/images/home-page/Illustration-components.png"
            alt="Components"
            imgClassName="illustration"
          />
        ) : (
          <StaticImage
            src="../assets/images/home-page/Illustration-components--mobile.png"
            alt="Components"
            imgClassName="illustration"
          />
        )}
      </div>
    </>
  );
  const ApiContent = () => (
    <>
      <div className="col-md-6">
        <h6 className="header-400 mb-3">API Builder</h6>
        <p className="body-300 gray-400">
          Design, call and expose APIs to share any data. Go from Zero to API in just under 60
          seconds and expose reliable, highly-available API Endpoints directly on Kubernetes without
          coding.
        </p>
        <a href="#demo" className="c-btn c-btn--white">
          Schedule a demo
        </a>
      </div>
      <div className="col-md-6">
        {!isMobile ? (
          <StaticImage
            src="../assets/images/home-page/Illustration-api-builder.png"
            alt="Components"
            imgClassName="illustration"
          />
        ) : (
          <StaticImage
            src="../assets/images/home-page/Illustration-api-builder--mobile.png"
            alt="Components"
            imgClassName="illustration"
          />
        )}
      </div>
    </>
  );
  const PatternsContent = () => (
    <>
      <div className="col-md-6">
        <h6 className="header-400 mb-3">Patterns</h6>
        <p className="body-300 gray-400">
          Route, orchestrate, mediate and govern your data-flows using any combination of enterprise
          integration patterns. Apache Camel ships the largest implementation of EIPs.
        </p>
        <a href="#demo" className="c-btn c-btn--white">
          Schedule a demo
        </a>
      </div>
      <div className="col-md-6">
        {!isMobile ? (
          <StaticImage
            src="../assets/images/home-page/Illustration-patterns.png"
            alt="Components"
            imgClassName="illustration"
          />
        ) : (
          <StaticImage
            src="../assets/images/home-page/Illustration-patterns--mobile.png"
            alt="Components"
            imgClassName="illustration"
          />
        )}
      </div>
    </>
  );
  const DataFormatsContent = () => (
    <>
      <div className="col-md-6">
        <h6 className="header-400 mb-3">Data Formats</h6>
        <p className="body-300 gray-400">
          Apache Camel natively speaks your business data format. From XML, JSON, CSV to HL7 - work
          with any data. EDI, SOAP, AS2? Yes, even those.
        </p>
        <a href="#demo" className="c-btn c-btn--white">
          Schedule a demo
        </a>
      </div>
      <div className="col-md-6">
        {!isMobile ? (
          <StaticImage
            src="../assets/images/home-page/Illustration-data-formats.png"
            alt="Components"
            imgClassName="illustration"
          />
        ) : (
          <StaticImage
            src="../assets/images/home-page/Illustration-data-formats--mobile.png"
            alt="Components"
            imgClassName="illustration"
          />
        )}
      </div>
    </>
  );
  const SwitchContent = () => {
    const [hoverRef, isHovered] = useHover();
    const contentsArr = ['Components', 'APIs', 'Patterns', 'Data formats'];

    useInterval(
      () => {
        if (count >= contentsArr.length - 1) {
          setCount(0);
        } else {
          setCount(count + 1);
        }
        setActiveLabel(contentsArr[count]);
      },
      !isHovered ? 3500 : null
    );
    return (
      <div ref={hoverRef} className="section-components__content row p-md-4 align-items-center">
        {activeLabel === 'Components' && <ComponentsContent />}
        {activeLabel === 'APIs' && <ApiContent />}
        {activeLabel === 'Patterns' && <PatternsContent />}
        {activeLabel === 'Data formats' && <DataFormatsContent />}
      </div>
    );
  };

  const MapButton = ({ className }) => (
    <Link to={Routes.CloudMapper} className={`c-btn c-btn--white ${className}`}>
      Learn more
    </Link>
  );

  return (
    <section className="fourth-section c-section--white overflow-hidden">
      <div className="container">
        <div className="row py-80">
          <div className="col-md-5">
            <div style={{ maxWidth: 360 }}>
              <h5 className="header-100 mb-3">
                <span className="c-label c-label--primary">1</span>
                <span className="ml-12 primary-600 text-uppercase">CAMEL STUDIO</span>
              </h5>
              <h3 className="header-600 mb-4">Build</h3>
              <h4 className="header-400 mb-4">
                Developing incredible APIs & Integrations just got a whole lot easier.
              </h4>
              {!isMobile ? (
                <p className="body-300 gray-400" style={{ lineHeight: '175%' }}>
                  Connect to any source using one of over 300{' '}
                  <a
                    href="#"
                    className={classNames('c-label py-0', {
                      'c-label--primary-full': activeLabel === 'Components',
                      'c-label--primary': activeLabel !== 'Components',
                    })}
                    onClick={(event) => handleActiveLabel(event, 'Components')}
                  >
                    Components
                  </a>
                  , expose{' '}
                  <a
                    href="#"
                    className={classNames('c-label py-0', {
                      'c-label--primary-full': activeLabel === 'APIs',
                      'c-label--primary': activeLabel !== 'APIs',
                    })}
                    onClick={(event) => handleActiveLabel(event, 'APIs')}
                  >
                    APIs
                  </a>
                  , orchestrate, transform and manipulate data using 100+{' '}
                  <a
                    href="#"
                    className={classNames('c-label py-0', {
                      'c-label--primary-full': activeLabel === 'Patterns',
                      'c-label--primary': activeLabel !== 'Patterns',
                    })}
                    onClick={(event) => handleActiveLabel(event, 'Patterns')}
                  >
                    Patterns
                  </a>{' '}
                  and 50+ native{' '}
                  <a
                    href="#"
                    className={classNames('c-label py-0', {
                      'c-label--primary-full': activeLabel === 'Data formats',
                      'c-label--primary': activeLabel !== 'Data formats',
                    })}
                    onClick={(event) => handleActiveLabel(event, 'Data formats')}
                  >
                    Data formats
                  </a>{' '}
                  Assemble your integrations by simply dragging and dropping from our library.{' '}
                  <br />
                </p>
              ) : (
                <p className="body-300 gray-400 mb-5" style={{ lineHeight: '175%' }}>
                  Use hundreds of pre-built components for better direct communication with your
                  partners - whether these are apps, systems, or cloud technologies.{' '}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-7 position-relative">
            <div className="c-decoration" style={{ top: -40, right: -130 }}>
              <img src={DecorationLines4} alt="decoration" />
            </div>
            <StaticImage
              className="mb-4 illustration"
              src="../assets/images/home-page/Illustration-build.png"
              alt="Build"
            />
          </div>
        </div>
        <div className="pb-80">
          <div className="section-components">
            <div className="section-components__label me-4">
              <span className="header-300 white">Camel Studio</span>
            </div>

            {!isMobile ? (
              <SwitchContent />
            ) : (
              <div className="section-components__content px-3 pt-4 pb-3">
                <Slider
                  {...{
                    arrows: true,
                    adaptiveHeight: true,
                    dots: false,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                  }}
                >
                  <div>
                    <ComponentsContent />
                  </div>
                  <div>
                    <ApiContent />
                  </div>
                  <div>
                    <PatternsContent />
                  </div>
                  <div>
                    <DataFormatsContent />
                  </div>
                </Slider>
              </div>
            )}
          </div>
        </div>
        <div className="row py-80">
          <div className="col-md-5">
            <div style={{ maxWidth: 360 }}>
              <h5 className="header-100 mb-3">
                <span className="c-label c-label--primary">2</span>
                <span className="ml-12 primary-600 text-uppercase">Cloud Mapper</span>
              </h5>
              <h3 className="header-600 mb-4">Map</h3>
              <h4 className="body-300 gray-400 mb-4">
                Fully integrated, visual and expandable data mapping solutions.
              </h4>
              <ul className="c-list body-300 gray-400 mb-4">
                <li>Native Apache Camel Integration</li>
                <li>Automatic structure detection</li>
                <li>Easy, visual mapping</li>
                <li>Multi-source document support</li>
              </ul>
              <div className="header-300 mb-12">Supports many data formats such as:</div>
              <div className="header-100 mb-md-4">
                <div className="c-label c-label--primary me-3 mb-3">Java</div>
                <span className="c-label c-label--teal me-3 mb-3">XML</span>
                <span className="c-label c-label--orange me-3 mb-3">CSV</span>
                <span className="c-label c-label--indigo me-3 mb-3">JSON</span>
                <span className="c-label c-label--pink me-3 mb-3">DFDL</span>
                <span className="c-label c-label--gray mb-3">
                  <div className="d-inline-flex align-middle">
                    <span className="c-circle c-circle--gray-300" />
                    <span className="c-circle c-circle--gray-400 mx-1" />
                    <span className="c-circle c-circle--gray-600" />
                  </div>
                </span>
              </div>
              {!isMobile && <MapButton />}
            </div>
          </div>
          <div className="col-md-7">
            {!isMobile ? (
              <StaticImage
                className="mb-4 illustration"
                src="../assets/images/home-page/Illustration-map.png"
                alt="Map"
              />
            ) : (
              <StaticImage
                className="mb-4 illustration"
                src="../assets/images/home-page/Illustration-map--mobile.png"
                alt="Map"
              />
            )}
          </div>
          {isMobile && (
            <div className="col-12">
              <MapButton className="d-block" />
            </div>
          )}
        </div>
        <SectionRun isMobile={isMobile} />
      </div>
    </section>
  );
};
const SectionFifth = () => (
    <section className="c-section--gray">
      <div className="container">
        <div className="heading-wrapper">
          <h3 className="header-600 mb-3">Which Integration Challenge are you Facing?</h3>
          <h4 className="body-300 gray-400 mb-64">
            Join thousands of developers solving the most complex integration challenges using
            Apache Camel on Kubernetes
          </h4>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="c-card">
              <div className="d-flex align-items-center mb-4">
                <img
                  src={iconRealTimeIntegration}
                  alt="Real-Time integration"
                  height={48}
                  width={48}
                />
                <h5 className="header-300 mb-0 ms-3">Real-Time Integration</h5>
              </div>
              <p className="body-300 gray-400">
                Quickly connect any of your data sources using over 300 pre-built components and
                more than 50 enterprise patterns.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="c-card">
              <div className="d-flex align-items-center mb-4">
                <img src={iconBuildApis} alt="Build APIs" height={48} width={48} />
                <h5 className="header-300 mb-0 ms-3">Build APIs</h5>
              </div>
              <p className="body-300 gray-400">
                Design, build and expose scalable APIs visually. Put your data to use by building
                your own custom APIs without coding.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="c-card">
              <div className="d-flex align-items-center mb-4">
                <img
                  src={iconDatabasesChangeData}
                  alt="Databases + Change Data Capture"
                  height={48}
                  width={48}
                />
                <h5 className="header-300 mb-0 ms-3">Data Lakes</h5>
              </div>
              <p className="body-300 gray-400">
                Build your own Data Lake using our database and Snowflake connectors. Automatically
                detect changes using CDC and stream live data into your lake.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="c-card">
              <div className="d-flex align-items-center mb-4">
                <img src={iconEventsStreaming} alt="Events & Streaming" height={48} width={48} />
                <h5 className="header-300 mb-0 ms-3">Events & Streaming</h5>
              </div>
              <p className="body-300 gray-400">
                Build your event-based streaming pipelines for IoT and process millions of
                transactions. We natively support Kafka.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="c-card">
              <div className="d-flex align-items-center mb-4">
                <img src={iconServeless} alt="Serverless Integration" height={48} width={48} />
                <h5 className="header-300 mb-0 ms-3">Serverless Integration</h5>
              </div>
              <p className="body-300 gray-400">
                Let Jetic handle your workloads on Kubernetes and eliminate the need for server
                management or costly migrations. Scale to zero when integrations are not needed to
                maximize efficiency.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-md-4">
            <div className="c-card">
              <div className="d-flex align-items-center mb-4">
                <img src={iconHybridCloudNative} alt="Hybrid Cloud-native" height={48} width={48} />
                <h5 className="header-300 mb-0 ms-3">Data Governance</h5>
              </div>
              <p className="body-300 gray-400">
                Manage your data quality and security with a proven enterprise framework leveraging
                dozens of pre-built patterns to work with your data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const SectionFifthAndHalf = () => (
    <section className="c-section--gray">
      <div className="container">
        <div className="heading-wrapper">
          <h3 className="header-600 mb-3">Jetic Professional and Consulting Services</h3>
        </div>
        <div className="row py-80">
          <div className="col-md-7">
            <StaticImage
              className="mb-4 illustration"
              src="../assets/images/home-page/professional-services.png"
              alt="Map"
            />
          </div>
          <div className="col-md-5">
            <div style={{ maxWidth: 360 }}>
              <h3 className="header-600 mb-4">Let us jumpstart your integration projects.</h3>
              <h4 className="body-300 gray-400 mb-4">
                With a jetic&trade; Enterprise plan, you'll gain access to our Professional and Consulting Services and Support team. Our engineers are experts in Apache Camel, Camel K, and Kubernetes, and together we implement your integration solution.
              </h4>
              <Link to={Routes.ContactUs} className="c-btn c-btn--white mt-4">
                Learn more
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="c-card">
              <div className="d-flex align-items-center mb-4">
                <img
                  src={iconRealTimeIntegration}
                  alt="Jetic Quickstart"
                  height={48}
                  width={48}
                />
                <h5 className="header-300 mb-0 ms-3">Jetic Quickstart</h5>
              </div>
              <ul>
                <li>
                  <p className="body-300 gray-400">
                    &#x26AC; Architecture design on-premises, in the cloud, and hybrid
                  </p>
                </li>
                <li>
                  <p className="body-300 gray-400">
                    &#x26AC; Jetic&trade; baseline installation and configuration
                  </p>
                </li>
                <li>
                  <p className="body-300 gray-400">
                    &#x26AC; Best practices, templates, and use case setup
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="c-card">
              <div className="d-flex align-items-center mb-4">
                <img src={iconEventsStreaming} alt="Platform Architecture" height={48} width={48} />
                <h5 className="header-300 mb-0 ms-3">Platform Architecture</h5>
              </div>
              <ul>
                <li>
                  <p className="body-300 gray-400">
                    &#x26AC; Strategic iPaaS and middleware guidelines
                  </p>
                </li>
                <li>
                  <p className="body-300 gray-400">
                    &#x26AC; Highly-available, resilient and scalable integration framework
                  </p>
                </li>
                <li>
                  <p className="body-300 gray-400">
                    &#x26AC; IntegrationOps from observability to automated CI/CD
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="c-card">
              <div className="d-flex align-items-center mb-4">
                <img
                  src={iconDatabasesChangeData}
                  alt="API & Integration Development"
                  height={48}
                  width={48}
                />
                <h5 className="header-300 mb-0 ms-3">API & Integration Development</h5>
              </div>
              <ul>
                <li>
                  <p className="body-300 gray-400">
                    &#x26AC; Development of complex APIs & Integrations
                  </p>
                </li>
                <li>
                  <p className="body-300 gray-400">
                    &#x26AC; Training for Cloud-native Integration Developers
                  </p>
                </li>
                <li>
                  <p className="body-300 gray-400">
                    &#x26AC; Playbooks for standardized Integrations
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

const SectionSixth = ({ isMobile }) => (
    <section className="c-section--white position-relative">
      <div className="c-decoration" style={{ top: -225 }}>
        <img src={DecorationLines3} alt="decoration" />
      </div>

      <div className="container">
        <div className="heading-wrapper">
          <span className="c-label c-label--primary header-100 mb-3">UNLEASH APACHE CAMEL</span>
          <h3 className="header-600 mb-3">Jetic - develop, manage and share with ease.</h3>
          <h4 className="body-300 gray-400 mb-64">
            Elevate developers and practitioners to innovate with powerful APIs & Integrations.
          </h4>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-64">
              {!isMobile ? (
                <StaticImage
                  src="../assets/images/home-page/Illustration-monitor-cluster-status.png"
                  alt="Monitor Cluster Status"
                  className="mb-32 illustration"
                />
              ) : (
                <StaticImage
                  src="../assets/images/home-page/Illustration-monitor-cluster-status--mobile.png"
                  alt="Monitor Cluster Status"
                  className="mb-32 illustration"
                  objectFit="contain"
                />
              )}
              <h5 className="header-400">Monitor Cluster Status</h5>
              <p className="body-300 gray-400">
                Use Jetic’s Control Hub to easily manage deployments and monitor the status of your
                integrations. All in one place.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-64">
              {!isMobile ? (
                <StaticImage
                  src="../assets/images/home-page/Illustration-integrate-with-git.png"
                  alt="Integrate with Git"
                  className="mb-32 illustration"
                />
              ) : (
                <StaticImage
                  src="../assets/images/home-page/Illustration-integrate-with-git--mobile.png"
                  alt="Integrate with Git"
                  className="mb-32 illustration"
                  objectFit="contain"
                />
              )}
              <h5 className="header-400">Integrated with Git</h5>
              <p className="body-300 gray-400">
                Jetic syncs all generated source-codes with your Git repo. Share work securely and
                integrate seamlessly with GitOps.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-64">
              {!isMobile ? (
                <StaticImage
                  src="../assets/images/home-page/Illustration-host-on-any-kubernetes.png"
                  alt="Host on any Kubernetes"
                  className="mb-32 illustration"
                />
              ) : (
                <StaticImage
                  src="../assets/images/home-page/Illustration-host-on-any-kubernetes--mobile.png"
                  alt="Host on any Kubernetes"
                  className="mb-32 illustration"
                  objectFit="contain"
                />
              )}
              <h5 className="header-400">Host on any Kubernetes</h5>
              <p className="body-300 gray-400">
                You can host Jetic on any Kubernetes. Whether in the Cloud, on-premises, behind your
                VPN or VPC and even on your local Minikube.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="">
              {!isMobile ? (
                <StaticImage
                  src="../assets/images/home-page/Illustration-apache-camel-in-overdrive.png"
                  alt="Apache Camel in Overdrive"
                  className="mb-32 illustration"
                />
              ) : (
                <StaticImage
                  src="../assets/images/home-page/Illustration-apache-camel-in-overdrive--mobile.png"
                  alt="Apache Camel in Overdrive"
                  className="mb-32 illustration"
                  objectFit="contain"
                />
              )}
              <h5 className="header-400">Apache Camel in Overdrive</h5>
              <p className="body-300 gray-400">
                There are thousands of capabilities hidden in the framework. We want to make sure
                you have them at your fingertips.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
const SectionSeventh = () => (
    <section className="c-section--gray">
      <div className="container">
        <div className="heading-wrapper">
          <h3 className="header-600 mb-3">Connect, share and govern</h3>
          <h4 className="body-300 gray-400 mb-64">
            A powerful building block with all the enterprise features you need.
          </h4>
        </div>
      </div>
      <div className="section-carousel">
        <div>
          <Slider
            {...{
              autoplay: true,
              autoplaySpeed: 0,
              dots: false,
              infinite: true,
              speed: 8000,
              slidesToShow: 3,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '180px',
              cssEase: 'linear',
              responsive: [
                {
                  breakpoint: 991.98,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ],
            }}
          >
            <div className="pe-4">
              <div className="c-card  mb-24">
                <div className="d-flex align-items-center mb-4">
                  <img src={iconConsumberSql} alt="Execute SQL queries" height={48} width={48} />
                  <h5 className="header-300 mb-0 ms-3">Execute SQL queries</h5>
                </div>
                <p className="body-200 gray-500">
                  Perform any database operation using JDBC operations.
                </p>
              </div>
            </div>
            <div className="pe-4">
              <div className="c-card mb-24">
                <div className="d-flex align-items-center mb-4">
                  <img src={iconMiscDelayer} alt="Execute SQL queries" height={48} width={48} />
                  <h5 className="header-300 mb-0 ms-3">Execute SQL queries</h5>
                </div>
                <p className="body-200 gray-500">
                  Perform any database operation using JDBC operations.
                </p>
              </div>
            </div>
            <div className="pe-4">
              <div className="c-card mb-24">
                <div className="d-flex align-items-center mb-4">
                  <img src={iconProducerLog} alt="Execute SQL queries" height={48} width={48} />
                  <h5 className="header-300 mb-0 ms-3">Execute SQL queries</h5>
                </div>
                <p className="body-200 gray-500">
                  Perform any database operation using JDBC operations.
                </p>
              </div>
            </div>
            <div className="pe-4">
              <div className="c-card mb-24">
                <div className="d-flex align-items-center mb-4">
                  <img src={iconTransformMap} alt="Execute SQL queries" height={48} width={48} />
                  <h5 className="header-300 mb-0 ms-3">Execute SQL queries</h5>
                </div>
                <p className="body-200 gray-500">
                  Perform any database operation using JDBC operations.
                </p>
              </div>
            </div>
          </Slider>
        </div>
        <div>
          <Slider
            {...{
              autoplay: true,
              autoplaySpeed: 0,
              dots: false,
              infinite: true,
              speed: 9000,
              slidesToShow: 3,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '120px',
              cssEase: 'linear',
              rtl: true,
              pauseOnHover: true,
              responsive: [
                {
                  breakpoint: 991.98,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ],
            }}
          >
            <div className="pe-4">
              <div className="c-card mb-24">
                <div className="d-flex align-items-center mb-4">
                  <img
                    src={iconOrchestrationLoop}
                    alt="Execute SQL queries"
                    height={48}
                    width={48}
                  />
                  <h5 className="header-300 mb-0 ms-3">Execute SQL queries</h5>
                </div>
                <p className="body-200 gray-500">
                  Perform any database operation using JDBC operations.
                </p>
              </div>
            </div>
            <div className="pe-4">
              <div className="c-card mb-24">
                <div className="d-flex align-items-center mb-4">
                  <img
                    src={iconRoutingRecipientList}
                    alt="Execute SQL queries"
                    height={48}
                    width={48}
                  />
                  <h5 className="header-300 mb-0 ms-3">Execute SQL queries</h5>
                </div>
                <p className="body-200 gray-500">
                  Perform any database operation using JDBC operations.
                </p>
              </div>
            </div>
            <div className="pe-4">
              <div className="c-card mb-24">
                <div className="d-flex align-items-center mb-4">
                  <img src={iconConsumerTimer} alt="Execute SQL queries" height={48} width={48} />
                  <h5 className="header-300 mb-0 ms-3">Execute SQL queries</h5>
                </div>
                <p className="body-200 gray-500">
                  Perform any database operation using JDBC operations.
                </p>
              </div>
            </div>
            <div className="pe-4">
              <div className="c-card mb-24">
                <div className="d-flex align-items-center mb-4">
                  <img src={iconApiPost} alt="Execute SQL queries" height={48} width={48} />
                  <h5 className="header-300 mb-0 ms-3">Execute SQL queries</h5>
                </div>
                <p className="body-200 gray-500">
                  Perform any database operation using JDBC operations.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
const SectionEight = ({ isMobile }) => {
  const Buttons = () => (
    <>
      <a
        href="https://datagrate.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="c-btn c-btn--primary me-md-3 mb-3 mb-md-0"
      >
        About us
      </a>
      <a href="mailto:andre.sluczka@datagrate.com" className="c-btn c-btn--white mb-3 mb-md-0">
        Contact Andre
      </a>
    </>
  );
  return (
    <section className="eight-section c-section--white position-relative overflow-hidden">
      <div className="container">
        <div className="row ps-md-5 align-items-center">
          <div className="col-md-6">
            <p className="body-600 gray-600 mb-4 mb-md-5 quote">
              With Jetic, we finally created a cloud-native solution for Apache Camel K which lowers
              the burden for newcomers and allows practitioners to use the framework at its full
              capacity - without limiting the functionality.
            </p>
            {!isMobile && (
              <div>
                <Buttons />
              </div>
            )}
          </div>
          <div className="col-md-5 offset-md-1 position-relative">
            <div className="c-decoration" style={{ top: -70, right: -75 }}>
              <img src={DecorationLines4} alt="decoration" />
            </div>
            {!isMobile && (
              <StaticImage
                src="../assets/images/home-page/Photo-Andre.png"
                alt="Andre Sluczka"
                height={460}
              />
            )}
            <div className="andre-label">
              {isMobile && (
                <StaticImage
                  src="../assets/images/home-page/Photo-Andre--mobile.png"
                  alt="Andre Sluczka"
                  height={72}
                />
              )}
              <div>
                <div className="header-300 primary-700">Andre Sluczka</div>
                <div className="body-200 gray-400">Founder &amp; CEO</div>
              </div>
            </div>
          </div>
          {isMobile && (
            <div className="d-flex flex-column mt-5">
              <Buttons />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const SectionRun = ({ isMobile }) => {
  const tabsData = [
    {
      tab: 'One-click Deployments',
      body: (
        <div>
          <p className="body-300 gray-400 mb-32">
            After clicking on run our platform performs the necessary actions and executes your
            integration directly on Kubernetes in under 10 seconds. We call this worry-free rapid
            development.
          </p>
          <div className="header-300 mb-12">
            The power of Apache Camel combined with Kubernetes.
          </div>
          <div className="d-flex align-items-center">
            <img src={logoCircleApacheCamel} alt="apache camel" height={50} width={50} />
            <IconCirclePlus className="mx-3" />
            <img src={logoCircleKubernetes} alt="kubernetes" height={50} width={50} />
          </div>
          <Link to={Routes.ControlHub} className="c-btn c-btn--white mt-4">
            Learn more
          </Link>
        </div>
      ),
      image: (
        <StaticImage
          className="mb-4 illustration"
          src="../assets/images/home-page/Illustration-runner.png"
          alt="Run"
        />
      ),
    },
    {
      tab: 'Live Flow Insights',
      body: (
        <div>
          <p className="body-300 gray-400 mb-32">
            Track and trace your data flowing through your integrations as you develop them in
            real-time. Our Data Analyzer gives you the needed insights.
          </p>
          <div className="header-300 mb-12">
            The power of Apache Camel combined with Kubernetes.
          </div>
          <div className="d-flex align-items-center">
            <img src={logoCircleApacheCamel} alt="apache camel" height={50} width={50} />
            <IconCirclePlus className="mx-3" />
            <img src={logoCircleKubernetes} alt="kubernetes" height={50} width={50} />
          </div>
          <Link to={Routes.ControlHub} className="c-btn c-btn--white mt-4">
            Learn more
          </Link>
        </div>
      ),
      image: (
        <StaticImage
          className="mb-4 illustration"
          src="../assets/images/home-page/Illustration-run.png"
          alt="Run"
        />
      ),
    },
  ];
  const [count, setCount] = useState(0);
  const [activeTab, setActiveTab] = useState(tabsData[0].tab);

  const activeData = tabsData.find((item) => item.tab === activeTab);

  const [hoverRef, isHovered] = useHover();

  useInterval(
    () => {
      if (!isMobile) {
        if (count >= tabsData.length - 1) {
          setCount(0);
        } else {
          setCount(count + 1);
        }
        setActiveTab(tabsData[count].tab);
      }
    },
    !isHovered ? 3500 : null
  );

  return (
    <div ref={hoverRef} className="section-run row py-80">
      <div className="col-md-5">
        <div style={{ maxWidth: 404 }}>
          <h5 className="header-100 mb-3">
            <span className="c-label c-label--primary">3</span>
            <span className="ml-12 primary-600 text-uppercase">Control Hub</span>
          </h5>
          <h3 className="header-600 mb-2 mb-mb-4">Run</h3>
          {!isMobile ? (
            <div className="tabs">
              <div className="tabs__header">
                {tabsData.map((item, index) => (
                  <div
                    className={`tabs__tab body-300 ${
                      activeTab === item.tab ? 'tabs__tab--active' : ''
                    }`}
                    key={index}
                    onClick={() => setActiveTab(item.tab)}
                  >
                    {item.tab}
                  </div>
                ))}
              </div>
              {activeData.body}
            </div>
          ) : (
            <div>
              <Slider
                {...{
                  arrows: true,
                  dots: false,
                  infinite: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  nextArrow: <NextArrow />,
                  prevArrow: <PrevArrow />,
                }}
              >
                {tabsData.map((item, index) => (
                  <div key={index}>
                    <div className="header-300 primary-700 mb-3 pb-3">{item.tab}</div>
                    {item.body}
                    {item.image}
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </div>
      {!isMobile && <div className="col-md-7">{activeData.image}</div>}
    </div>
  );
};

export default IndexPage;
